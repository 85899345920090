.wysiwyg-wrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
}
.wysiwyg-editor {
    height: auto;
    min-height: 100px;
    padding: 0 10px;
    resize: vertical;
}
.wysiwyg-toolbar {
    margin-bottom: 2px;
    background: #c0f2d9;
}
.rdw-option-wrapper {

}
.rdw-option-wrapper:hover {
    box-shadow: 0 0 0 0.2rem #00ad6d;
}
.rdw-option-active {
    background: #40d88b;
    border-color: #40d88b;
}